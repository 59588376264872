import { Badge, ListViewProduct, ProductPrice } from '@activebrands/core-web/types/product';
import { algoliaFacetDataExtraction, algoliaFacetPriceExtraction } from '@activebrands/core-web/utils/algolia-utils';
import createProductVariant from '@activebrands/core-web/utils/product/create-product-variant';
import createRelatedVariations from '@activebrands/core-web/utils/product/create-related-variations';
import parseCategoriesListView from '@activebrands/core-web/utils/product/parse-categories-list-view';
import transformProductPageViewMedia from '@activebrands/core-web/utils/product/transform-product-page-view-media';
import transformTechnologies from '@activebrands/core-web/utils/product/transform-technologies';
import isObject from '@grebban/utils/object/isObject';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

type AllowedSoruces = 'GrebCommerce' | 'Algolia';

/**
 * Transforms data that is ready to use with <ProductCard>
 * @param product - Raw product data
 * @param source - Source of the product data, can be supplied from Algolia or GrebCommerce API
 * @param pricelistId - Algolia data needs the price list id to extract the correct price
 * @param marketId - Algolia data needs the market id to extract the correct price and stock status
 * @returns ListViewProduct
 */

export const createListViewProduct = (
    product: Record<string, any>,
    source: AllowedSoruces = 'GrebCommerce',
    pricelistId?: string,
    marketId?: string
): ListViewProduct => {
    const customAttributes = product?.custom_attributes || {};

    const parentSku = product.parent_sku;

    let attrColorName;
    let attrCommercialBadge;
    let attrTechnologies;
    let categoryIds;
    let images;
    let inStock;
    let price;
    let productSku;
    let recommendationId;

    switch (source) {
        case 'GrebCommerce': {
            attrColorName = customAttributes.attr_color?.name;
            attrCommercialBadge = customAttributes.attr_commercial_badge;
            attrTechnologies = customAttributes.attr_technologies;
            categoryIds = product.categories?.map((c: any): string => c.id);
            images = product.media;
            inStock = product.stock?.inStock;
            price = product.price ? (objectKeysToCamelCase(product.price) as ProductPrice) : undefined;
            productSku = product.product_sku;
            recommendationId = product.depict_recommendation_id;
            break;
        }
        case 'Algolia': {
            attrColorName = product.color?.name;
            attrCommercialBadge = product.commercial_badge;
            attrTechnologies = product.technologies;
            categoryIds = product.filter_categories?.map(cat => algoliaFacetDataExtraction(cat).internalName);
            images = product.images;
            inStock = !!product.in_stock_markets?.[marketId || ''];
            productSku = product.sku;

            if (product.prices && pricelistId && marketId) {
                const extractedPrice = algoliaFacetPriceExtraction(product.prices, pricelistId, marketId);
                if (extractedPrice) {
                    price = extractedPrice;
                }
            }
            break;
        }
        default:
            break;
    }

    // Add related variations (colors)
    const relatedVariations = createRelatedVariations(product, marketId, true);

    // Add commercial badges
    const badges: Badge[] = [];
    if (attrCommercialBadge) {
        if (attrCommercialBadge.group_text) {
            badges.push({
                internalName: attrCommercialBadge.group_text,
                displayText: attrCommercialBadge.group_text,
                style: { color: attrCommercialBadge.group_text_color },
            });
        }
        if (attrCommercialBadge.text) {
            badges.push({
                internalName: attrCommercialBadge.text,
                displayText: attrCommercialBadge.text,
                style: { color: attrCommercialBadge.text_color },
            });
        }
    }

    // Add technologies
    const technologies =
        attrTechnologies && isObject(attrTechnologies)
            ? transformTechnologies(objectKeysToCamelCase(attrTechnologies))
            : [];

    return {
        badges,
        categories: parseCategoriesListView(product.categories?.map(objectKeysToCamelCase)),
        categoryIds,
        color: product.variant_name,
        defaultSort: 0,
        id: product.id,
        inStock,
        media: transformProductPageViewMedia({ images, source }),
        name: product.name,
        objectID: product.objectID,
        parentSku,
        price,
        productSku,
        recommendationId,
        productType: product.type === 'bundle' ? 'bundle' : 'product',
        relatedVariations,
        sku: product.sku,
        technologies,
        // If the product is a teamstore product, use the teamstore uri format
        uri: /^\d+$/.test(product.teamstore_id)
            ? `${process.env.REACT_APP_TEAMSTORE_PRODUCT_PATH}?${product.uri?.replace(/[\/]|(\?v=)/g, '')}`
            : product.uri,
        variations: product.variations?.map(variant => createProductVariant(product, variant)),
    };
};

export default createListViewProduct;
