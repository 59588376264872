import { ProductTracking } from '@activebrands/core-web/types/tracking';

const createProductTrackingMeta = (product: Record<string, any>, variant: Record<string, any>): ProductTracking => {
    return {
        brandName: product.brand?.name,
        ean: variant.ean,
        item: variant.id,
        productName: product.name,
        priceAsNumber: product.price?.salePrice || product.price?.sale_price,
        sku: variant.sku,
        size: variant.name,
        parentId: product.id,
        silkVariant: product.variant_id,
    };
};

export default createProductTrackingMeta;
