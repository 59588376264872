import { RawTechnology, Technology } from '@activebrands/core-web/types/product';

/**
 * Transforms Technology data
 * @param technologies - the array of Technologies to be transformed
 * @returns an array of Technology objects with name and imageUrl properties
 */

const transformTechnologies = (technologies: Record<string, RawTechnology>): Array<Technology> | undefined => {
    if (technologies instanceof Object && Object.keys(technologies)?.length) {
        return Object.values(technologies).map(({ code, details, filterGroup, name, image }) => ({
            code,
            details,
            filterGroup,
            name,
            imageUrl: image?.url,
        }));
    }
    return undefined;
};

export default transformTechnologies;
