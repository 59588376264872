import { ProductListViewCategory } from '@activebrands/core-web/types/product';

const parseCategoriesListView = (categories: ProductListViewCategory[]): ProductListViewCategory[] =>
    categories
        ? categories.map(category => ({
              id: category.id,
              name: category.name,
              categories: parseCategoriesListView(category.categories),
          }))
        : [];

export default parseCategoriesListView;
