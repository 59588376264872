import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import headerHeights from 'config/branding/header-heights';
import useBasketQuery from 'queries/useBasketQuery';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { parseRecentlyViewedCookie } from '@activebrands/core-web/state/recentlyViewed/actions';
import { inBrowser, isIOS } from '@activebrands/core-web/utils/constants';
import { getDepictProducts } from '@activebrands/core-web/utils/grebcommerce';
import createListViewProduct from '@activebrands/core-web/utils/product/create-list-view-product';
import loadable from '@loadable/component';
import {
    BASKET,
    BASKET_EMPTY,
    RECENTLY_VIEWED,
    RECENTLY_VIEWED_EMPTY,
    WISHLIST,
    WISHLIST_AUTHENTICATED_EMPTY,
    WISHLIST_UNAUTHENTICATED,
} from 'components/Header/constants';
import ThemeButton from 'components/buttons/ThemeButton';
import BasketIcon from 'components/icons/BasketIcon';
import StateIcon from 'components/icons/StateIcon';
import Paragraph from 'components/text/Paragraph';
// import WishlistIcon from 'components/icons/WishlistIcon';
import Cookies from 'js-cookie';

const Wrapper = styled('div', {
    width: 'var(--width-basket-overlay)',
    maxWidth: '600px',
    height: `calc(var(--vh) - var(--height-header) - 8px)`, //8px since there is no gap around header on mobile
    display: 'flex',
    position: 'relative',
    margin: '4px',
    flexDirection: 'column',
    backgroundColor: 'var(--color-bg-highlight)',
    overflow: 'hidden',

    [media.min['desktop.sm']]: {
        margin: '8px 4px',
        height: `calc(var(--vh) - var(--height-header) - 12px)`,
    },
});

const NavWrapper = styled('ul', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '12px',
    overflowX: 'scroll',
    boxSizing: 'content-box',
    flexBasis: 'auto',
    borderBottom: '1px solid var(--color-border-basket)',
    padding: '16px 0px',

    // Hide scrollbar in safari and chrome
    '::-webkit-scrollbar': {
        display: 'none',
    },

    // Hide scrollbar in firefox
    scrollbarWidth: 'none',
});

const BasketOverlay = () => {
    const [css] = useStyletron();
    const basket = useSelector(state => state.basket);
    const recentlyViewedList = useSelector(state => state.recentlyViewedList);
    const { headerHeight } = headerHeights;
    const { items } = basket;
    const hasProductsInBasket = items?.length > 0;
    const content = useBasketQuery();

    const shouldShowProducts = items?.length > 0;

    const views = {
        [BASKET]: loadable(() => import('components/Header/BasketOverlay/Basket')),
        [BASKET_EMPTY]: loadable(() => import('components/Header/BasketOverlay/BasketEmpty')),
        [WISHLIST]: loadable(() => import('components/Header/BasketOverlay/Wishlist')),
        [WISHLIST_UNAUTHENTICATED]: loadable(() =>
            import('components/Header/BasketOverlay/Wishlist/WishlistUnauthenticated')
        ),
        [WISHLIST_AUTHENTICATED_EMPTY]: loadable(() =>
            import('components/Header/BasketOverlay/Wishlist/WishlistAuthenticatedEmpty')
        ),
        [RECENTLY_VIEWED]: loadable(() => import('components/Header/BasketOverlay/RecentlyViewed')),
        [RECENTLY_VIEWED_EMPTY]: loadable(() =>
            import('components/Header/BasketOverlay/RecentlyViewed/RecentlyViewedEmpty')
        ),
    };

    const initialView = hasProductsInBasket ? BASKET : BASKET_EMPTY;
    const [view, setView] = useState(initialView);

    const ViewComponent = views[view];

    // const selectWishlist = () => {
    // TODO: Add condition for empty, empty-authenticated, or existing wishlist
    // Check if authenticated in auth state
    // if (false) {
    //     // Check if wishlist is empty in auth state
    //     if (true) {
    //         setView(WISHLIST_AUTHENTICATED_EMPTY);
    //     } else {
    //         setView(WISHLIST);
    //     }
    // } else {
    // setView(WISHLIST_UNAUTHENTICATED);
    // }
    // };

    const selectRecentlyViewed = () => {
        if (recentlyViewedList.items.length > 0) {
            return setView(RECENTLY_VIEWED);
        }
        const recentlyViewedListCookie = Cookies.get(`${process.env.REACT_APP_COOKIES_RECENTLY_VIEWED}`);
        const parsedCookieArray = parseRecentlyViewedCookie(recentlyViewedListCookie);

        if (parsedCookieArray.length > 0) {
            return setView(RECENTLY_VIEWED);
        }
        setView(RECENTLY_VIEWED_EMPTY);
    };

    const selectBasket = () => {
        if (shouldShowProducts) {
            setView(BASKET);
        } else {
            setView(BASKET_EMPTY);
        }
    };

    const basketItems = useSelector(s => s.basket.items);
    const numberOfItems = basketItems.reduce((sum, item) => (sum += item.quantity), 0);
    const [heightState, setHeightState] = useState(null);
    const marketId = useSelector(state => state.application.shopConfig.marketId);
    const aliasId = useSelector(state => state.application.aliasId);
    const pricelistId = useSelector(state => state.application.shopConfig.pricelistId);
    const [upsellProducts, setUpsellProducts] = useState([]);

    const itemIds = items.map(item => item.productId).join();

    const setMobileOverlayHeight = () => {
        // Set overlay height to the area not covered by the iOS keyboard (& the nav area subtracted)
        const overlayHeight = window?.visualViewport ? `${window.visualViewport.height - 8}` : null;
        setHeightState(overlayHeight);
    };

    // Get upsell products from Depict
    useEffect(async () => {
        const related = await getDepictProducts({
            marketId: marketId,
            pricelistId: pricelistId,
            productIds: itemIds,
            siteId: aliasId,
            type: 'after_basket',
        });

        const products = (related ?? []).map(product => {
            return createListViewProduct(product, 'GrebCommerce', pricelistId, marketId);
        });
        setUpsellProducts(products);
    }, [items, marketId, aliasId, pricelistId]);

    useEffect(() => {
        // Handle window resizing for android's on focus (Keyboard makes the screen smaller)

        if (isIOS) {
            // Fixes iOS safari space occupied by browser address bar
            document.documentElement.style.minHeight = '100vh';

            window.visualViewport.addEventListener('resize', setMobileOverlayHeight);

            if (!inBrowser) {
                return;
            }

            return () => {
                window.visualViewport.removeEventListener('resize', setMobileOverlayHeight);
                document.documentElement.style.minHeight = 'initial';
            };
        }
    }, []);

    return (
        <Wrapper>
            <div
                className={css({
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                    padding: '12px',
                    borderBottom: '1px solid var(--color-border-basket)',
                })}
            >
                <Paragraph fontKeys="Primary/16_100_-05">{fm('Your products')}</Paragraph>
                <div
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        height: '100%',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                    })}
                    onClick={() => overlay.close('basket')}
                >
                    <StateIcon size="16px" state="reset" />
                </div>
            </div>
            <div>
                <NavWrapper>
                    <ThemeButton
                        $style={{
                            cursor: 'pointer',
                            flexBasis: 0,
                            flexGrow: 1,
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '8px',
                            marginLeft: ['12px', null, null, null, '16px'],
                            padding: '8px 12px',
                            height: '40px',
                        }}
                        active={view === BASKET || view === BASKET_EMPTY}
                        as="li"
                        theme="outlined"
                        onClick={() => {
                            selectBasket();
                        }}
                    >
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                            })}
                        >
                            <BasketIcon width="16px" />
                            <Paragraph>{content.basketNavigationLabel}</Paragraph>
                        </div>
                        <div>({numberOfItems})</div>
                    </ThemeButton>
                    {/* <ThemeButton
                        $style={{
                            cursor: 'pointer',
                            borderRadius: '40px',
                            flexBasis: 0,
                            flexGrow: 1,
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                        active={
                            view === WISHLIST ||
                            view === WISHLIST_UNAUTHENTICATED ||
                            view === WISHLIST_AUTHENTICATED_EMPTY
                        }
                        as="li"
                        onClick={() => {
                            selectWishlist();
                        }}
                    >
                        <WishlistIcon width="16px" />
                        <Paragraph>{content.wishlistNavigationLabel}</Paragraph>
                    </ThemeButton> */}
                    <ThemeButton
                        $style={{
                            cursor: 'pointer',
                            flexBasis: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexGrow: 1,
                            textAlign: 'left',
                            display: 'flex',
                            marginRight: ['12px', null, null, null, '16px'],
                            height: '40px',
                        }}
                        active={view === RECENTLY_VIEWED || view === RECENTLY_VIEWED_EMPTY}
                        as="li"
                        theme="outlined"
                        onClick={() => {
                            selectRecentlyViewed();
                        }}
                    >
                        {content.recentlyViewedNavigationLabel}
                    </ThemeButton>
                </NavWrapper>
            </div>
            <div
                className={css({
                    height: isIOS ? `calc(${heightState}px - ${headerHeight}px * 1.5)` : '100%', //Current vh - header in top and bottom
                    overflow: 'auto',
                })}
            >
                <ViewComponent content={content} upsellProducts={upsellProducts} />
            </div>
        </Wrapper>
    );
};

export default BasketOverlay;
