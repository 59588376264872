import { ProductVariant } from '@activebrands/core-web/types/product';
import createProductTrackingMeta from '@activebrands/core-web/utils/product/create-product-tracking-meta';

const createProductVariant = (product: Record<string, any>, variant: Record<string, any>): ProductVariant => ({
    id: variant.id,
    name: variant.name,
    sku: variant.sku,
    inStock: !!variant.inStock || !!variant.in_stock,
    uri: variant.uri,
    silkVariantId: product.variant_id,
    silkProductId: product.parent_id,
    silkItemId: variant.id.split("-")[1],
    trackingMeta: createProductTrackingMeta(product, variant),
});

export default createProductVariant;
